'use client'

import React from 'react'
import dynamic from 'next/dynamic'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import LoadableComponent from 'components/LoadableComponent'

import { type HomepageSlottingType } from '../../_utils/services/homepageSlotting'

const CategorySection = dynamic(() => import('../CategorySection'))
const CatalogueSection = dynamic(() => import('../CatalogueSection'))
const BannerSection = dynamic(() => import('../BannerSection'))
const DynamicChannelSlotting = dynamic(() => import('../DynamicChannel/DynamicChannelSlotting'))

type SlottingContainerPropsType = {
  initialSlottingData: HomepageSlottingType[]
}

const SlottingContainer = ({ initialSlottingData }: SlottingContainerPropsType) => {
  const slottingData = initialSlottingData

  const renderSlottingContent = (content: HomepageSlottingType) => {
    switch (content.sectionType) {
      case 'banner': {
        return (
          <Box mt="16px">
            <BannerSection />
          </Box>
        )
      }
      case 'catalog': {
        return (
          <Box mt="16px">
            <CatalogueSection />
          </Box>
        )
      }
      case 'category': {
        return (
          <Box mt="16px">
            <CategorySection />
          </Box>
        )
      }
      case 'dynamic-channel': {
        return (
          <LoadableComponent>
            <DynamicChannelSlotting id={content.dynamicChannelId} />
          </LoadableComponent>
        )
      }
      default:
        return null
    }
  }

  return (
    <>
      {slottingData.map((i) => {
        const key = i.id

        return <React.Fragment key={key}>{renderSlottingContent(i)}</React.Fragment>
      })}
    </>
  )
}

export default SlottingContainer
