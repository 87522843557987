'use client'

import React, { useEffect, useMemo } from 'react'
import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { color } from '@astronautsid/wpe-astro-ui/tokens/color'
import { useQuery } from '@tanstack/react-query'
import useUserStore from 'store/user'

import Link from 'next/link'
import ProductCard from 'components/ProductCard'
import { mixpanelEventCategory, mixpanelEventName } from 'config/mixpanelConstants'
import { TrackEventsPropertiesType, trackEvents } from 'lib/mixpanelTracker'
import { getDetailDynamicChannel } from '../../_utils/services/dynamicChannel'
import DynamicChannelSkeleton from './components/DynamicChannelSkeleton'

type DynamicChannelSlottingPropsType = {
  id: number
  boxProps?: BoxPropsType
}

const DynamicChannelSlotting = ({ id, boxProps }: DynamicChannelSlottingPropsType) => {
  const { customerAddress } = useUserStore((state) => state)

  const locationId = customerAddress.location_id || 0

  const { data: dynamicChannelResponse, isLoading } = useQuery({
    queryKey: ['dynamic channel detail', id],
    queryFn: () =>
      getDetailDynamicChannel(id, {
        location_id: locationId,
      }),
  })

  const dynamicChanneDetail = useMemo(
    () =>
      dynamicChannelResponse?.data.data || {
        title: '',
        products: [],
        category_id: [],
      },
    [dynamicChannelResponse?.data.data],
  )

  useEffect(() => {
    if (!isLoading && dynamicChanneDetail.products.length) {
      const items = dynamicChanneDetail.products.map((item, index) => ({
        item_list_id: index,
        quantity: item.productStock,
        item_id: item.productId,
        price: item.productPrice,
        item_name: item.productName,
        item_brand: '',
        item_variant: '',
        item_category: '',
        item_category3: '',
        item_category4: '',
        item_category5: item.productDiscountPrice,
        promotion_id: '',
      }))
      trackEvents(mixpanelEventName.VIEW_ITEM_LIST, {
        eventCategory: mixpanelEventCategory.HOME_PAGE,
        eventAction: 'view product card - recommendation',
        eventLabel: dynamicChanneDetail.title,
        items,
        item_list: `/homepage - product list - recommendation ${dynamicChanneDetail.title}`,
      })
    }
  }, [dynamicChanneDetail, isLoading])

  const handleTrackEventClick = (
    event: string,
    properties: TrackEventsPropertiesType,
    item: (typeof dynamicChanneDetail.products)[0],
    index: number,
  ) => {
    trackEvents(event, {
      eventCategory: mixpanelEventCategory.HOME_PAGE,
      eventLabel: dynamicChanneDetail.title,
      items: [
        {
          item_list_id: index,
          quantity: item.productStock,
          item_id: item.productId,
          price: item.productPrice,
          item_name: item.productName,
          item_brand: '',
          item_variant: '',
          item_category: '',
          item_category3: '',
          item_category4: '',
          item_category5: item.productDiscountPrice,
          promotion_id: '',
        },
      ],
      item_list: `/homepage - product list - recommendation ${dynamicChanneDetail.title}`,
      ...properties,
    })
  }

  if (isLoading) {
    return <DynamicChannelSkeleton />
  }

  if (!dynamicChanneDetail.products.length) {
    return null
  }

  return (
    <Box {...boxProps}>
      <Box marginTop="16px">
        <Box marginBottom="8px" display="flex" justifyContent="space-between" gap="8px" px="16px">
          <Typography component="h2" variant="headline-default">
            {dynamicChanneDetail.title}
          </Typography>
          <Link href={`/d/${id}`}>
            <Typography variant="body-tinyStrong" color={color.textColor.link} whiteSpace="nowrap">
              Lihat Semua
            </Typography>
          </Link>
        </Box>
        <Box display="flex" overflow="auto" gap="14px" className="box-hide-scroll" px="16px">
          {dynamicChanneDetail.products.map((product, indexProduct) => (
            <Box key={`dynamic-channel-product-${+indexProduct + 1}`}>
              <ProductCard
                boxPropsContainer={{
                  width: '110px',
                }}
                productData={{
                  astroCoin: product.astroCoin,
                  productDiscountDailyQuota: product.productDiscountDailyQuota,
                  productDiscountPercentage: product.productDiscountPercentage,
                  productDiscountPrice: product.productDiscountPrice,
                  productDiscountStock: product.productDiscountStock,
                  productFavorite: product.productFavorite,
                  productGrammationLabel: product.productGrammationLabel,
                  productID: product.productId,
                  productInventoryDiscountId: product.productInventoryDiscountId,
                  productName: product.productName,
                  productPrice: product.productPrice,
                  productStock: product.productStock,
                  productUrlImage: product.productUrlImage,
                  productVolume: product.productVolume,
                  productWeight: product.productWeight,
                  isEighteen: product.isTwentyOne,
                  productCategoryIds: dynamicChanneDetail.category_id,
                  productSlug: product.product_slug,
                  inventoryDiscountTierLabel: product?.inventory_discount_tier_label,
                }}
                source="dynamicchannel"
                onClick={() =>
                  handleTrackEventClick(
                    mixpanelEventName.SELECT_ITEM,
                    {
                      eventAction: 'click product card - recommendation',
                    },
                    product,
                    indexProduct,
                  )
                }
                onAddToCart={() =>
                  handleTrackEventClick(
                    mixpanelEventName.ADD_TO_CART,
                    {
                      eventAction: 'click add to cart on product card - recommendation',
                    },
                    product,
                    indexProduct,
                  )
                }
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default DynamicChannelSlotting
