import Skeleton from '@mui/material/Skeleton'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import { HeaderStyled } from 'app/(home)/_components/HomeHeader/style'
import { mixpanelEventCategory } from 'config/mixpanelConstants'
import GlobalSearchBar from 'components/GlobalSearchBar'

const HomeHeader = ({ isLogin = false }: { isLogin?: boolean }) => (
  <HeaderStyled px="16px" py="12px">
    <Box display="flex" flexDirection="column" gap="2px">
      <Skeleton width="150px" height="22px" variant="rounded" />
      <Skeleton width="70%" height="18px" variant="rounded" />
      {isLogin && (
        <Box mt="8px">
          <Skeleton width="50%" height="30px" variant="rounded" />
        </Box>
      )}
    </Box>
    <Box display="flex" gap="8px" alignItems="center" mb="18px" mt="16px">
      <GlobalSearchBar boxProps={{ flex: 1 }} source={mixpanelEventCategory.HOME_PAGE} />
    </Box>
  </HeaderStyled>
)

export default HomeHeader
