'use client'

import React from 'react'

import ProductCard from 'components/ProductCard'

import { DynamicChannelProductType } from '../../../_utils/services/dynamicChannel'

type DynamicChannelProductPropsType = {
  product: DynamicChannelProductType
}

const DynamicChannelProduct = ({ product }: DynamicChannelProductPropsType) => (
  <ProductCard
    boxPropsContainer={{
      width: '110px',
    }}
    productData={{
      astroCoin: product.astro_coin,
      productDiscountDailyQuota: product.product_discount_daily_quota,
      productDiscountPercentage: product.product_discount_percentage,
      productDiscountPrice: product.product_discount_price,
      productDiscountStock: product.product_discount_stock,
      productFavorite: product.product_favorite,
      productGrammationLabel: product.product_grammation_label,
      productID: product.product_id,
      productInventoryDiscountId: product.product_inventory_discount_id,
      productName: product.product_name,
      productPrice: product.product_price,
      productStock: product.product_stock,
      productUrlImage: product.product_url_image,
      productVolume: product.product_volume,
      productWeight: product.product_weight,
      productLabels: product.product_labels,
      productCategoryIds: product.category_ids || [],
      isEighteen: product.is_eighteen,
      productSlug: product.product_slug,
      inventoryDiscountTierLabel: product?.inventory_discount_tier_label,
    }}
    source="dynamicchannel"
  />
)

export default DynamicChannelProduct
