'use client'

import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import AlertUnreachAddress from './AlertUnreachAddress'
import AlertLocationClose from './AlertLocationClose/AlertLocationClose'
import useAlertErrorAddress from './useAlertErrorAddress'

type AlertErrorAddressPropsType = {
  boxPropsContainer?: BoxPropsType
}

const AlertErrorAddress = ({ boxPropsContainer }: AlertErrorAddressPropsType) => {
  const { hasLocationCloseReason, unreachableAddress, closeReasonTitle, closeReasonContent } =
    useAlertErrorAddress()

  const renderContent = () => {
    if (unreachableAddress) {
      return <AlertUnreachAddress />
    }

    if (hasLocationCloseReason) {
      return <AlertLocationClose title={closeReasonTitle} content={closeReasonContent} />
    }

    return null
  }

  return <Box {...boxPropsContainer}>{renderContent()}</Box>
}

export default AlertErrorAddress
