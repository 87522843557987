import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import Skeleton from '@mui/material/Skeleton'

import { BANNER_ASPECT_RATIO, MAX_WIDTH_BANNER_CONTAINER } from './config'

const BannerSkeleton = () => (
  <Box
    position="relative"
    sx={{ aspectRatio: BANNER_ASPECT_RATIO }}
    margin="0 auto"
    {...MAX_WIDTH_BANNER_CONTAINER}
  >
    <Skeleton width="100%" height="100%" variant="rounded" />
  </Box>
)

export default BannerSkeleton
