'use client'

import { useEffect } from 'react'

import useUserStore, { UserType } from 'store/user'

import getOrderDetail from 'app/order/_services/orderDetail/http'
import { putUserSessionService } from 'app/login/_utils/services'
import { getBranchPurchaseTempData, setBranchPurchaseTempData } from './serviceLocalStorage'
import { trackPurchase, trackFirstPurchase } from './eventActions'

const BranchPurchaseTracking = () => {
  const { user } = useUserStore((state) => state)

  useEffect(() => {
    const logsPurchasedOrders = async () => {
      const currentBranchPurchaseData = getBranchPurchaseTempData()

      const purchaseOrdersPromises = currentBranchPurchaseData.map(async (i) => {
        const { orderId } = i.eventDataAndCustomData

        const { data } = await getOrderDetail({
          orderID: orderId,
        })

        return data
      })

      const purchasedOrders = await Promise.all(purchaseOrdersPromises)

      let shouldUpdateNewUserSession = false

      const alteredBranchPurchaseData = currentBranchPurchaseData.map((i) => ({
        ...i,
        delete: false,
      }))

      purchasedOrders.forEach((order, idx) => {
        const { eventDataAndCustomData, contentItems } = alteredBranchPurchaseData[idx]

        // Need to check financial_status of order 'Payment Verified'.
        if (order?.financial_status === 'Payment Verified') {
          trackPurchase({
            eventDataAndCustomData,
            contentItems,
          })

          trackFirstPurchase({
            isNewBuyer: !!user?.is_new_buyer,
            eventDataAndCustomData,
            contentItems,
            callback: () => {
              shouldUpdateNewUserSession = true
            },
          })

          alteredBranchPurchaseData[idx].delete = true
        }

        /**
         * financial_status other than 'Waiting Payment' will be deleted by default. ex: For orders that have failed due to cancelation.
         */
        if (order?.financial_status !== 'Waiting Payment') {
          alteredBranchPurchaseData[idx].delete = true
        }
      })

      // Update to storage
      const newBranchPurchaseData = alteredBranchPurchaseData.filter((i) => !i.delete)
      setBranchPurchaseTempData(newBranchPurchaseData)

      if (shouldUpdateNewUserSession) {
        putUserSessionService({
          user: {
            ...user,
            is_new_buyer: false,
          } as UserType,
        })
      }
    }

    logsPurchasedOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default BranchPurchaseTracking
