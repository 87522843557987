'use client'

import React from 'react'
import Link from 'next/link'

import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { color } from '@astronautsid/wpe-astro-ui/tokens'
import { Label } from '@astronautsid/wpe-astro-ui/components/atoms/Label'
import { Plus } from '@astronautsid/wpe-icons'
import { useShallow } from 'zustand/react/shallow'

import { ASSET_PREFIX } from 'config/constants'
import CartIncrement from 'components/CartIncrement/CartIncrement'
import calculateBuyMore from 'utils/helpers/calculateBuyMore'
import currencyFormat from 'utils/priceFormatter'
import useCart from 'hooks/useCart'
import { trackAddToCart } from 'lib/branch/eventActions'
import useCategoryCatalogueStore, {
  type CategoryCatalogueSliceType,
} from 'store/category&catalogue/category&catalogueSlice'
import Image from 'components/Image'
import useIntersection from 'hooks/useIntersection'
import { mixpanelEventName } from 'config/mixpanelConstants'
import { trackEachProduct } from 'config/mixpanelEventActions'
import useUserStore, { UserType } from 'store/user'
import useUser from 'hooks/useUser'

type InventoryDiscountTierLabelType = {
  qty: number
  discount_price: number
  discount_price_fmt: string
  discount_percentage: number
  discount_percentage_fmt: string
  final_price: number
  final_price_fmt: string
  label: string
}

export type ProductCardDataType = {
  astroCoin?: number
  productPrice?: string
  productDiscountPrice?: string
  productDiscountPercentage?: string
  productDiscountStock?: number
  productDiscountDailyQuota?: number
  productInventoryDiscountId?: number
  productName?: string
  productStock?: number
  productFavorite?: boolean
  productGrammationLabel?: string
  productUrlImage?: string
  productLabels?: {
    label_id: number
    label_name: string
    label_type_id: number
    label_icon_url: string
    label_color_id: number
    label_color_name: string
    label_color_hexacode: string
    label_color_hexafont: string
    label_color_description: string
    is_primary: boolean
  }[]
  productID: number
  productVolume?: number
  productWeight?: string
  infoSection?: string
  buyMoreSection?: string
  inventoryDiscountTierLabel?: InventoryDiscountTierLabelType[]
  productCategoryIds: number[]
  isEighteen: boolean
  productSlug?: string
}

type ProductCardPropsType = {
  boxPropsContainer?: BoxPropsType
  productData: ProductCardDataType
  /**
   * Possible value 'cart' | 'pdp' | 'dynamicChannel' | 'search' | 'category' | 'catalogue'
   */
  source: string
  trackerData?: {
    eventData: {
      [k: string]: string | object
    }
    eventAction: {
      viewProduct?: string
      clickProduct?: string
      addToCart?: string
    }
  }
  onClick?: () => void
  onAddToCart?: () => void
  onViewProduct?: () => void
}

const selectCategoryCatalogueStore = ({
  setIsShowConfirmationAge,
}: CategoryCatalogueSliceType) => ({
  setIsShowConfirmationAge,
})

const ProductCard = (props: ProductCardPropsType) => {
  const { isLoggedIn } = useUserStore((state) => state)
  const { login } = useUser()

  const { cartData, addToCartFunction } = useCart({ silentError: true })

  const { setIsShowConfirmationAge } = useCategoryCatalogueStore(
    useShallow(selectCategoryCatalogueStore),
  )

  const { user: currentUser } = useUserStore((state) => state)

  const { productData, source = ``, boxPropsContainer, trackerData, onClick, onAddToCart } = props
  const {
    productPrice = ``,
    productDiscountPrice = ``,
    productName,
    productStock = 0,
    productGrammationLabel,
    productUrlImage,
    productLabels = [],
    productID,
    buyMoreSection = ``,
    inventoryDiscountTierLabel = [],
    productDiscountPercentage,
    isEighteen,
    productSlug,
  } = productData

  let shownProductPrice = ``
  let labelContent = ``
  let showProductDiscount = false
  let discountPercentage = `-`

  const instantCart = cartData.find((item) => item.cart_type.toLowerCase() === 'instant')
  const selectedProduct = instantCart?.items?.find((item) => item.product_id === productID)

  const selectedQuantity = selectedProduct?.quantity || 0

  const productImage = productUrlImage || `${ASSET_PREFIX}/img/placeholder-product.svg`

  // track mixpanel view each product card event
  const productRef = useIntersection({
    callback: () => {
      if (trackerData?.eventAction?.viewProduct && trackerData?.eventData) {
        trackEachProduct(mixpanelEventName.VIEW_ITEM_LIST, {
          ...trackerData.eventData,
          eventAction: trackerData.eventAction.viewProduct,
        })
      }
    },
    onlyOnce: true,
    options: { threshold: [0.95] },
  })

  // BMSM section
  switch (true) {
    // for tiered discount flow. (buy more save more)
    case inventoryDiscountTierLabel.length > 0:
      {
        const convertedDiscountTier = inventoryDiscountTierLabel.map((item) => ({
          quantity: item.qty,
          discountPercentage: item.discount_percentage_fmt,
          label: item.label,
          finalPrice: item.final_price,
        }))

        const { discountPrice, discountLabel, discountPercentageStr } = calculateBuyMore(
          convertedDiscountTier,
          selectedQuantity,
          productPrice,
        )
        shownProductPrice = discountPrice
        labelContent = discountLabel
        discountPercentage = discountPercentageStr
        showProductDiscount = discountPercentageStr !== ``
      }
      break
    // for normal discount flow.
    case productDiscountPrice !== ``:
      showProductDiscount = true
      shownProductPrice = productDiscountPrice
      discountPercentage = productDiscountPercentage || ``
      break
    // default price
    default:
      shownProductPrice = productPrice
      break
  }

  const labelCold = productLabels.find((label) => label.label_name === 'Dingin')

  const handleAddToCart = async () => {
    await addToCartFunction({
      cart_type: 'INSTANT',
      product_id: productID,
      quantity: 1,
      is_loyalty_product: false,
      loyalty_location_id: 0,
      pwp_campaign_id: 0,
      total_price_quickmart: 0,
      send_as_gift: false,
      note: null,
      source,
      bottom_sheet_current_page: '',
      modifier_variant_ids: null,
    })

    // track add to cart event
    if (trackerData?.eventAction?.addToCart && trackerData?.eventData) {
      trackEachProduct(mixpanelEventName.ADD_TO_CART, {
        ...trackerData.eventData,
        eventAction: trackerData.eventAction.addToCart,
      })
    }

    trackAddToCart({
      user: currentUser as UserType,
      source,
      action: 'add',
      product: {
        productID,
        productName,
        productStock,
        productPrice,
      },
      additionalBUOData: {
        $quantity: 1,
      },
    })

    if (onAddToCart) {
      onAddToCart()
    }
  }

  const plusMethod = () => {
    if (!isLoggedIn) {
      login()

      return
    }

    if (!currentUser?.is_eighteen_plus && isEighteen) {
      setIsShowConfirmationAge(true)
    } else {
      handleAddToCart()
    }
  }

  const handleOnClickLink = () => {
    // track click product card
    if (trackerData?.eventAction?.clickProduct && trackerData?.eventData) {
      trackEachProduct(mixpanelEventName.SELECT_ITEM, {
        ...trackerData.eventData,
        eventAction: trackerData.eventAction.clickProduct,
      })
    }

    if (onClick) onClick()
  }

  const productDetailLink = `/p/${productSlug || productID}`

  return (
    <Box
      ref={productRef}
      flexDirection="column"
      justifyContent="flex-start"
      border="1px solid"
      borderColor={color.strokeColor.default}
      borderRadius="8px"
      height="100%"
      {...boxPropsContainer}
    >
      <Box position="relative">
        {/* add quantity and snowflake (chill product) section */}
        {selectedQuantity === 0 && productStock !== 0 && (
          <>
            <Box
              position="absolute"
              top="4px"
              right="4px"
              width="26px"
              height="26px"
              onClick={plusMethod}
              borderRadius="80px"
              style={{ cursor: 'pointer', border: `1px solid ${color.iconColor.primary}` }}
              bgcolor={color.bgColor.light}
            >
              <Box width="100%" height="100%" position="relative">
                <Box
                  display="inline-flex"
                  position="absolute"
                  left="50%"
                  top="50%"
                  sx={{ transform: 'translate(-50%, -50%)' }}
                >
                  <Plus size={14} color={color.iconColor.primary} type="solid" />
                </Box>
              </Box>
            </Box>
            <Box width={20} height={20} position="absolute" left="4px" top="6px">
              {Boolean(labelCold) && (
                <Image
                  src={labelCold?.label_icon_url || `${ASSET_PREFIX}/img/snowflake.svg`}
                  alt="test"
                  fill
                />
              )}
            </Box>
          </>
        )}

        {selectedQuantity > 0 && productStock !== 0 && (
          <CartIncrement
            productId={productID}
            propsBox={{ position: 'absolute', top: '4px', right: 0, left: 0, mx: '4px' }}
            source={source}
            maxStock={productStock}
            showBMSM
          />
        )}

        <Link href={productDetailLink} onClick={handleOnClickLink}>
          <Box sx={{ aspectRatio: 1, mb: '4px' }}>
            <Image
              key={`-product-image-${productID}`}
              src={productImage}
              alt="test"
              style={{ opacity: productStock === 0 ? '0.5' : '1', maxWidth: 'unset', zIndex: -1 }}
              fill
            />
          </Box>
        </Link>
      </Box>
      <Box p="8px" pt={0}>
        <Link href={productDetailLink} onClick={handleOnClickLink}>
          {labelContent && (
            <Box
              alignItems="center"
              justifyContent="center"
              display="flex"
              width="auto"
              bgcolor={color.bgColor.lightRed}
              margin="4px -8px"
            >
              <Label size="small" color="red" text={labelContent} />
            </Box>
          )}
          {showProductDiscount && (
            <Box display="flex" gap="2px" alignItems="center" mb="2px">
              <Label size="small" filled color="red" text={discountPercentage} />
              <Typography
                style={{ textDecoration: 'line-through' }}
                color={color.textColor.disable}
                variant="caption-tiny"
              >
                {currencyFormat(Number(productPrice))}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap="2px" overflow="hidden">
            <Typography variant="body-smallStrong">
              {currencyFormat(Number(shownProductPrice))}
            </Typography>
            <Typography
              variant="paragraph-tiny"
              lineHeight="16px"
              overflow="hidden"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {productName}
            </Typography>
            {productGrammationLabel !== `` && (
              <Typography color={color.textColor.disable} variant="caption-tiny">
                {productGrammationLabel}
              </Typography>
            )}
            {buyMoreSection !== `` && (
              <Typography color={color.textColor.warning} variant="caption-tinyBold">
                {buyMoreSection}
              </Typography>
            )}
          </Box>
          {/* astro coint section is not yet implemented */}
          {/* {astroCoin > 0 && (
          <Box display="flex" gap="2px">
            <Box height={16} width={16}>
              <Image src="/img/astro-coin.svg" alt="test" fill />
            </Box>
            <Typography
              variant="caption-tinyBold"
              color={color.textColor.link}
              margin="auto 0px"
              style={{ lineHeight: '15px' }}
            >
              +{astroCoin} Koin
            </Typography>
          </Box>
        )} */}
          {productStock === 0 && (
            <Box>
              <Typography color={color.textColor.error} variant="caption-tiny">
                Habis
              </Typography>
            </Box>
          )}
        </Link>
      </Box>
    </Box>
  )
}

export default ProductCard
