'use client'

import { useEffect, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'

import useUserStore from 'store/user'
import { TrackEventsPropertiesType } from 'lib/mixpanelTracker'
import { trackClickButtonDownloadApps } from 'config/mixpanelEventActions'
import { LINK_BANNER_INSTALL_APP } from 'config/constants'

const persist = {
  name: 'astro-app-installer',
  value: 'true',
}

function useAppInstallBanner() {
  const pathname = usePathname()
  const { setIsShowAstroAppBanner, customerAddress } = useUserStore((state) => state)
  const [bottom, setBottom] = useState<null | number>(null)

  const searchParams = useSearchParams()

  const isWebview = searchParams.get('webview') === 'true' ?? false

  useEffect(() => {
    if (sessionStorage.getItem(persist.name) !== persist.value) {
      const nav = document.querySelector('.bottom-navigation')

      setBottom(nav ? 52 : 0)
      setIsShowAstroAppBanner(true)
    }
  }, [pathname, setIsShowAstroAppBanner])

  const handleToOpen = () => {
    const properties: TrackEventsPropertiesType = {
      eventAction: `click download apps - button`,
      locationId: `${customerAddress.location_id}`,
    }

    trackClickButtonDownloadApps(properties)

    window.open(LINK_BANNER_INSTALL_APP, '_blank')
  }

  const handleToClose = () => {
    sessionStorage.setItem(persist.name, persist.value)
    setBottom(null)
    setIsShowAstroAppBanner(false)
  }

  return { bottom, isWebview, handleToOpen, handleToClose }
}

export default useAppInstallBanner
