'use client'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { styled } from '@astronautsid/wpe-astro-ui/theme'

export const HeaderStyled = styled(Box)`
  background: rgb(0, 0, 0);
  background: linear-gradient(
    122deg,
    rgba(0, 0, 0, 1) 5%,
    rgba(6, 41, 168, 1) 46%,
    rgba(155, 207, 249, 1) 100%
  );
`
