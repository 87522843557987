import axiosInstanceApiGateway from 'config/apiServiceConfig'
import type { PageableType } from './liteApiTypes'

const { Get } = axiosInstanceApiGateway

// banner types

export type GetBannerDataType = {
  content: GetBannerContentType[]
  pageable: PageableType
  totalPages: number
  last: boolean
  totalElements: number
  first: boolean
  sort: {
    sorted: boolean
    unsorted: boolean
    empty: boolean
  }
  numberOfElements: number
  size: number
  number: number
  empty: boolean
}

export type GetBannerContentType = {
  banner_id: number
  banner_name: string
  category_id?: number
  banner_position: number
  banner_url_image: string
  banner_url_link?: string
  banner_active: boolean
  is_catalogue: boolean
  targeting_type: number
}

// ENDOF banner types

export const getBanner = (query: string) =>
  Get<GetBannerDataType, true>({
    url: `/api/banner?${query}`,
  })
