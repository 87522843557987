'use client'

import dynamic from 'next/dynamic'
import { createContext, Fragment, useContext, useMemo } from 'react'
import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import useQueryBanner from 'app/(home)/_hooks/useQueryBanner'
import { type GetBannerDataType } from 'utils/apiList/lite/banner'

import {
  BANNER_ASPECT_RATIO,
  MAX_WIDTH_BANNER_CONTAINER,
} from 'app/(home)/_components/BannerSection/config'
import Image from 'components/Image'
import { ASSET_PREFIX } from 'config/constants'
import { getComposedBannerURL } from 'utils/url/bannerUrl'

import BannerSkeleton from './BannerSkeleton'

export const BannerContext = createContext<
  Nullable<{
    banners: GetBannerDataType['content']
    boxProps?: BoxPropsType
  }>
>(null)

const BannerSectionPlaceholder = () => {
  const bannerContext = useContext(BannerContext)
  const boxProps = bannerContext?.boxProps || {}
  const banners = bannerContext?.banners || []

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        aspectRatio: BANNER_ASPECT_RATIO,
        margin: '0 auto',
        px: '16px',
        pt: '7px',
        overflow: 'hidden',
        display: 'block',
      }}
    >
      {banners?.map((banner, i) => (
        <Fragment key={banner.banner_id}>
          <Box
            component="a"
            borderRadius="4px"
            sx={{
              position: i < 1 ? 'relative' : 'absolute',
              overflow: 'hidden',
              width: 'calc(100% - 16px)',
              transform: i > 0 ? 'translateX(-1000%)' : 'none',
            }}
            {...boxProps}
            {...MAX_WIDTH_BANNER_CONTAINER}
            href={getComposedBannerURL(banner) || ''}
          >
            <Image
              src={banner.banner_url_image || `${ASSET_PREFIX}/img/placeholder-product.svg`}
              alt={banner.banner_name}
              style={{
                borderRadius: '4px',
              }}
              fill
              priority
            />
          </Box>
        </Fragment>
      ))}
    </Box>
  )
}

const BannerSliderSection = dynamic(() => import('./BannerSliderSection/BannerSliderSection'), {
  ssr: false,
  loading: () => <BannerSectionPlaceholder />,
})

type BannerSectionPropsType = {
  boxProps?: BoxPropsType
  initialData?: GetBannerDataType
}

const BannerSection = ({ boxProps, initialData }: BannerSectionPropsType) => {
  const { data: bannerData, isLoading } = useQueryBanner({ initialData })

  const contentBanner = useMemo(() => {
    if (!bannerData) {
      return {
        banners: [],
        boxProps,
      }
    }

    return {
      banners: bannerData?.content?.filter((i) => !!i.banner_url_image) || [],
      boxProps,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerData?.content])

  const renderContent = () => {
    if (isLoading) {
      return <BannerSkeleton />
    }

    if (!isLoading && !contentBanner.banners.length) {
      return null
    }

    return <BannerSliderSection banners={contentBanner.banners} />
  }

  return (
    <BannerContext.Provider value={contentBanner}>
      <Box {...boxProps}>{renderContent()}</Box>
    </BannerContext.Provider>
  )
}

export default BannerSection
