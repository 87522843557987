import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { CatalogueSectionPropsType } from 'app/(home)/_components/CatalogueSection/types'
import CategoryCatalogueCard from 'app/(home)/_components/CategoryCatalogueCard'

const CatalogueSectionPlaceholder = ({ boxProps, initialData }: CatalogueSectionPropsType) => {
  const titleCatalogue = initialData?.title || ''
  const contentList = initialData?.content || []
  const shownList = contentList.filter((content) => !content.is_hidden)
  return (
    <Box {...boxProps}>
      <Typography variant="headline-default" px="16px">
        {titleCatalogue}
      </Typography>
      <Box
        style={{ overflowX: 'scroll' }}
        marginTop="8px"
        className="box-hide-scroll"
        paddingRight="8px"
        px="16px"
      >
        <Box display="inline-flex" gap="14px">
          {shownList.map((content, index) => (
            <CategoryCatalogueCard
              key={`home-catalogue-${index + 1}`}
              title={content.category_name}
              image={content.category_url_image || ``}
              boxPropsContainer={{ width: '110px', height: '110px', borderRadius: '8px' }}
              imageProps={{
                width: 110,
                height: 110,
                style: { borderRadius: '8px', objectFit: 'cover', width: '100%' },
                fill: false,
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default CatalogueSectionPlaceholder
