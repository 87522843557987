'use client'

import React from 'react'
import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { color } from '@astronautsid/wpe-astro-ui/tokens/color'
import Link from 'next/link'
import { useQuery } from '@tanstack/react-query'

import useUserStore from 'store/user'

// import { trackEvents } from 'lib/mixpanelTracker'
// import { mixpanelEventCategory, mixpanelEventName } from 'config/mixpanelConstants'
import {
  getDynamicChannel,
  type DynamicChannelInterface,
} from '../../_utils/services/dynamicChannel'
import DynamicChannelSkeleton from './components/DynamicChannelSkeleton'
import DynamicChannelProduct from './components/DynamicChannelProduct'

type CatalogueSectionPropsType = {
  boxProps?: BoxPropsType
  initialData?: DynamicChannelInterface
}

const DynamicChannel = ({ boxProps, initialData }: CatalogueSectionPropsType) => {
  const { customerAddress } = useUserStore((state) => state)

  const { data: dynamicChannelResponse, isLoading } = useQuery({
    queryKey: ['dynamic channel'],
    queryFn: async () => {
      const resp = await getDynamicChannel({
        locationid: customerAddress.location_id,
        factive: true,
        fmproduct: true,
        showhub: false,
      })

      return resp.data
    },
    initialData,
  })

  const dynamicChannelList = dynamicChannelResponse?.data || []

  // TODO:
  // commented since the track events itself is somehow commented.
  // it was not before because of the console log
  // useEffect(() => {
  //   if (!isLoading && dynamicChannelList.length) {
  //     // dynamicChannelList.forEach((item) => {
  //     //   // trackEvents(mixpanelEventName.VIEW_ITEM_LIST, {
  //     //   //   eventCategory: mixpanelEventCategory.HOME_PAGE,
  //     //   //   eventAction: 'view product card - recommendation',
  //     //   //   eventLabel: item.dynamic_channel_title,
  //     //   //   items: []
  //     //   // })
  //     // })
  //   }
  // }, [dynamicChannelList, isLoading])

  if (isLoading) {
    return <DynamicChannelSkeleton />
  }

  if (dynamicChannelList.length === 0) {
    return null
  }

  return (
    <Box {...boxProps}>
      {dynamicChannelList.map((content, index) => (
        <Box key={`dynamic-channel-${index + 1}`} marginTop="16px" px="16px">
          <Box marginBottom="8px" display="flex" justifyContent="space-between" gap="8px">
            <Typography component="h2" variant="headline-default">
              {content.dynamic_channel_title}
            </Typography>
            <Link href={`/d/${content.dynamic_channel_id}`}>
              <Typography
                variant="body-tinyStrong"
                color={color.textColor.link}
                whiteSpace="nowrap"
              >
                Lihat Semua
              </Typography>
            </Link>
          </Box>
          <Box display="flex" overflow="auto" gap="14px" className="box-hide-scroll">
            {content.products.map((product, indexProduct) => (
              <Box key={`dynamic-channel-product-${indexProduct + 1}`}>
                <DynamicChannelProduct product={product} />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default DynamicChannel
