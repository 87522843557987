import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import LoadingComponent from 'app/(home)/_components/CatalogueSection/_components/LoadingComponent'
import BannerSkeleton from 'app/(home)/_components/BannerSection/BannerSkeleton'

const DefaultHomepageSkeleton = () => (
  <Box mt="24px" display="flex" flexDirection="column" gap="16px">
    <BannerSkeleton />
    <LoadingComponent />
    <LoadingComponent />
  </Box>
)

export default DefaultHomepageSkeleton
