import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import CategoryCatalogueCard from 'app/(home)/_components/CategoryCatalogueCard'
import { CategorySectionPropsType } from 'app/(home)/_components/CategorySection/types'

const CategorySectionPlaceholder = ({ boxProps, initialData }: CategorySectionPropsType) => {
  const titleCategory = initialData?.title || ''
  const contentList = initialData?.content || []
  const shownList = contentList.filter((content) => !content.is_hidden)
  return (
    <Box px="16px" {...boxProps}>
      <Typography variant="headline-default">{titleCategory}</Typography>
      <Box display="grid" gap="14px" paddingTop="16px" gridTemplateColumns="repeat(3, 1fr)">
        {shownList.map((content, index) => (
          <CategoryCatalogueCard
            key={`home-category-${index + 1}`}
            title={content.category_name}
            image={content.category_url_image || ``}
            boxPropsContainer={{ width: '100%', height: '100%' }}
            isCategory
          />
        ))}
      </Box>
    </Box>
  )
}

export default CategorySectionPlaceholder
