import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@astronautsid+wpe-astro-ui@2.11.2_@emotion+react@11.13.0_@emotion+styled@11.13.0_@mui+material@5.16.6_react@18.3.1/node_modules/@astronautsid/wpe-astro-ui/components/atoms/Box/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/@astronautsid+wpe-astro-ui@2.11.2_@emotion+react@11.13.0_@emotion+styled@11.13.0_@mui+material@5.16.6_react@18.3.1/node_modules/@astronautsid/wpe-astro-ui/components/atoms/Typography/Typography.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["initDatadogLog","log","parsedStackFrame","setUserData"] */ "/app/node_modules/.pnpm/@astronautsid+wpe-utils@2.7.2_@datadog+browser-logs@4.50.1_axios@1.7.2_dayjs@1.11.12_error-st_rjkmr4vo7qls3dvnt2wpgnex4y/node_modules/@astronautsid/wpe-utils/helpers/datadog/datadog.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@emotion+styled@11.13.0_@types+react@18.2.48_react-dom@18.3.1_react@18.3.1/node_modules/@mui/material/Skeleton/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.3.0-canary.30_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.3.0-canary.30_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.3.0-canary.30_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(categoryandcatalogue)/_components/BottomSheetConfirmationAge/BottomSheetConfirmationAge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/BannerSection/BannerSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/CatalogueSection/Container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/CategorySection/Container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/DynamicChannel/DynamicChannel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/DynamicChannel/DynamicChannelSlotting.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/HomeBottomContent/HomeBottomContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/HomeHeader/HomeHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderStyled"] */ "/app/src/app/(home)/_components/HomeHeader/style.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/SearchBarFloating/SearchBarFloating.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(home)/_components/SlottingContainer/SlottingContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AppInstallBanner/AppInstallBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BottomNavigation/BottomNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CartWidget/CartWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/GlobalSearchBar/GlobalSearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Image/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Location/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/TopNav/TopNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/lib/branch/BranchPurchaseTracking.ts");
;
import(/* webpackMode: "eager" */ "/app/src/store/user/sessionStoreProvider.tsx");
