import type { OrderHistoryMetaInterface } from 'app/order/_types/order'
import type {
  OrderDetailOrderHistoryStatusInterface,
  OrderDetailOrderSLAInterface,
} from 'app/order/_types/orderDetail'

export const ORDER_HISTORY_FALLBACK_META_RESPONSE: OrderHistoryMetaInterface = {
  empty: true,
  last: true,
  totalElements: 0,
  totalPages: 0,
}

export const ORDER_HISTORY_STATUS = {
  ARRIVE_DESTINATION: 'Pesanan Sampai Tujuan',
  CANCELLED: 'Pesanan Dibatalkan',
  COMPLETED: 'Pesanan Sudah Diterima',
  FINISH_PROCESS: 'Pesanan Selesai Diproses',
  ON_DELAY_DISTANCE: 'Pesanan Berpotensi Terlambat',
  ON_DELIVERY: 'Pesanan Sedang Dikirim',
  ON_PROCESS: 'Pesanan Diproses',
  PAYMENT_VERIFIED: 'Pembayaran Diterima',
  WAITING_PAYMENT: 'Menunggu Pembayaran',
}

export const ORDER_DETAIL_STATUS = {
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  IN_REVIEW: 'In Review',
  NEW_ORDER: 'New Order',
  ON_DELIVERY: 'On Delivery',
  PACKING: 'Packing',
  PAYMENT_EXPIRED: 'Payment Expired',
}

export const ORDER_DETAIL_ORDER_HISTORY_FALLBACK: OrderDetailOrderHistoryStatusInterface[] = [
  {
    id: 1,
    title: 'Pembayaran Diterima',
    timestamp: 0,
    content: '',
    active: false,
  },
  {
    id: 2,
    title: 'Pesanan Sedang Diproses',
    timestamp: 0,
    content: '',
    active: false,
  },
  {
    id: 3,
    title: 'Pesanan Sedang Dikirim',
    timestamp: 0,
    content: '',
    active: false,
  },
  {
    id: 4,
    title: 'Pesanan Sudah Diterima',
    timestamp: 0,
    content: '',
    active: false,
  },
]

export const ORDER_DETAIL_SLA_FALLBACK: OrderDetailOrderSLAInterface = {
  backgroundColor: '#E9F1FC',
  fontColor: '#246EE5',
  text: 'Pengiriman 30-45 min',
}
