'use client'

import dynamic from 'next/dynamic'
import { useMemo, createContext, useContext } from 'react'

import { CategorySectionPlaceholder } from 'app/(home)/_components/Placeholders'
import type { CategorySectionPropsType } from './types'

const CategoryContext = createContext<Nullable<CategorySectionPropsType>>(null)

const Fallback = () => {
  const ctx = useContext(CategoryContext)
  return <CategorySectionPlaceholder {...ctx} />
}

const CategorySection = dynamic(
  () => import('app/(home)/_components/CategorySection/CategorySection'),
  {
    ssr: false,
    loading: () => <Fallback />,
  },
)

const CategorySectionContainer = ({ boxProps, initialData }: CategorySectionPropsType) => {
  const context = useMemo(() => ({ initialData, boxProps }), [initialData, boxProps])
  return (
    <CategoryContext.Provider value={context}>
      <CategorySection initialData={initialData} boxProps={boxProps} />
    </CategoryContext.Provider>
  )
}

export default CategorySectionContainer
