'use client'

import { createContext, useContext, useMemo } from 'react'
import dynamic from 'next/dynamic'
import { CatalogueSectionPlaceholder } from 'app/(home)/_components/Placeholders'
import { CatalogueSectionPropsType } from 'app/(home)/_components/CatalogueSection/types'

const CatalogueContext = createContext<Nullable<CatalogueSectionPropsType>>(null)

const Fallback = () => {
  const ctx = useContext(CatalogueContext)
  return <CatalogueSectionPlaceholder {...ctx} />
}

const CatalogueSection = dynamic(
  () => import('app/(home)/_components/CatalogueSection/CatalogueSection'),
  {
    ssr: false,
    loading: () => <Fallback />,
  },
)

const CatalogueSectionContainer = ({ boxProps, initialData }: CatalogueSectionPropsType) => {
  const context = useMemo(() => ({ initialData, boxProps }), [initialData, boxProps])
  return (
    <CatalogueContext.Provider value={context}>
      <CatalogueSection initialData={initialData} boxProps={boxProps} />
    </CatalogueContext.Provider>
  )
}

export default CatalogueSectionContainer
