import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import Skeleton from '@mui/material/Skeleton'

const LoadingComponent = () => (
  <Box overflow="hidden" px="16px">
    <Skeleton width="150px" height="16px" variant="rounded" />
    <Box display="inline-flex" gap="16px" paddingTop="16px">
      <Skeleton sx={{ margin: '0 auto' }} width="110px" height="110px" variant="rounded" />
      <Skeleton sx={{ margin: '0 auto' }} width="110px" height="110px" variant="rounded" />
      <Skeleton sx={{ margin: '0 auto' }} width="110px" height="110px" variant="rounded" />
      <Skeleton sx={{ margin: '0 auto' }} width="110px" height="110px" variant="rounded" />
      <Skeleton sx={{ margin: '0 auto' }} width="110px" height="110px" variant="rounded" />
    </Box>
  </Box>
)

export default LoadingComponent
